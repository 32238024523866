@import '../breakpoints.scss';
@import '../variables.scss';

.header_container {
  background-color: $white;
  position: fixed;
  top: 0;
  z-index: 4;
  @media (max-width: $breakpoint-md) {
    width: 100%;
  }
  .header_inner {
    max-width: 100vw;
    margin: auto;
    padding: 1.667vw 2.222vw;
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      padding: 24px 16px;
      justify-content: space-between;
    }
    .logo_container {
      width: 15.556vw;
      margin-right: 10.069vw;
      cursor: pointer;
      @media (max-width: $breakpoint-md) {
        width: 214px;
        order: 1;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .wrapper_mobile {
      display: flex;
      @media (max-width: $breakpoint-md) {
        order: 2;
        align-items: center;
      }
    }
    .burger_menu {
      display: none;
      @media (max-width: $breakpoint-md) {
        display: block;
        order: 3;
        width: 24px;
        height: 17px;
        position: relative;

        span {
          height: 2px;
          transition: all 0.3s ease;
          position: absolute;
          width: 18px;
          background: $red_orange_50_gradient;
          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            top: 7px;
            width: 14px;
          }
          &:nth-child(3) {
            top: 14px;
          }
        }
      }
      &.burger_open {
        span {
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(1) {
            top: 6px;
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            top: 6px;
            transform: rotate(-45deg);
          }
        }
      }
    }
    .menu_items {
      display: flex;
      align-items: center;
      gap: 3.194vw;

      @media (max-width: $breakpoint-md) {
        gap: unset;
        background-color: $white;
        position: absolute;
        width: 100%;
        left: 0;
        top: 96px;
        flex-direction: column;
        padding: 0;
        align-items: flex-start;
        overflow: hidden;
        max-height: 0;
        transition: max-height 1s;
        flex-direction: column-reverse;
      }
      &.open {
        max-height: 100vh;
      }
      .header_search {
        width: 10.556vw;
        height: 3.333vw;
        @media (max-width: $breakpoint-md) {
          order: 1;
          width: 100%;
          height: 100%;
          padding: 48px 64px 32px 64px;
        }
        input {
          width: 100%;
          height: 100%;
          font-size: 0.972vw;
          line-height: 150%;
          border: none;
          border-radius: 2.222vw;
          color: $medium_grey;
          font-weight: 300;
          padding-left: 2.917vw;
          background: $light_grey;
          background-image: url('/search.svg');
          background-size: 1.111vw 1.111vw;
          background-repeat: no-repeat;
          background-position: 1.111vw;
          &:focus {
            outline: none;
          }
          @media (max-width: $breakpoint-md) {
            padding-left: 42px;
            background-size: 16px 16px;
            font-size: 14px;
            border-radius: 32px;
            background-position: 20px;
            width: 279px;
            height: 48px;
          }
        }
        /* clears the 'X' from Internet Explorer */
        input[type='search']::-ms-clear {
          display: none;
          width: 0;
          height: 0;
        }
        input[type='search']::-ms-reveal {
          display: none;
          width: 0;
          height: 0;
        }

        /* clears the 'X' from Chrome */
        input[type='search']::-webkit-search-decoration,
        input[type='search']::-webkit-search-cancel-button,
        input[type='search']::-webkit-search-results-button,
        input[type='search']::-webkit-search-results-decoration {
          display: none;
        }
      }
    }
    .menu_button {
      width: 3.333vw;
      height: 3.333vw;
      margin-left: 8.611vw;
      @media (max-width: $breakpoint-md) {
        order: 2;
        width: 48px;
        height: 48px;
        margin: 0 27px 0 0;
      }
      button {
        width: 100%;
        height: 100%;
        border: none;
        background-color: $white;
        cursor: pointer;
        background-image: url('/header_button.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
