@import '../variables.scss';

.footer {
  background: $dark_grey;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'TWKLausanne-300';
  .upper_footer {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 5.556vw 14.167vw 2.222vw 14.167vw;
    @media (max-width: $breakpoint-md) {
      flex-flow: column;
      padding: 32px 16px;
    }
    .scrollTop {
      position: absolute;
      width: 3.333vw;
      height: 3.333vw;
      top: -1.667vw;
      right: 2.222vw;
      background: $white;
      cursor: pointer;
      box-shadow: 0px 48px 48px $box_shadow;
      border-radius: 6.944vw;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $breakpoint-md) {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        right: 16px;
        top: -14px;
      }
      img {
        width: 1.667vw;
        height: 1.667vw;
        @media (max-width: $breakpoint-md) {
          width: 16px;
          height: 16px;
        }
      }
    }
    .upper_logo_links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .logo {
        position: relative;
        display: block;
        padding-bottom: 2.847vw;
        @media (max-width: $breakpoint-md) {
          padding-bottom: 32px;
        }
      }
      .upper_links {
        display: flex;
        flex-flow: column nowrap;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: $medium_grey;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 21px;
        }
        a {
          padding-bottom: 0.556vw;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            width: 1.667vw;
            height: 1.667vw;
            margin-right: 0.556vw;
            background-size: cover;
            display: inline-block;
            @media (max-width: $breakpoint-md) {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
          &:first-child {
            &::before {
              background-image: url('../../public/mail.svg');
            }
          }
          &:nth-child(2) {
            &::before {
              background-image: url('../../public/phone.svg');
            }
          }
          @media (max-width: $breakpoint-md) {
            padding-bottom: 8px;
          }
        }
        span {
          display: flex;
          align-items: center;
          &::before {
            background-image: url('../../public/location_pin.svg');
            content: '';
            width: 1.667vw;
            height: 1.667vw;
            margin-right: 0.556vw;
            background-size: cover;
            display: inline-block;
            @media (max-width: $breakpoint-md) {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .upper_cta {
      display: flex;
      flex-direction: column;

      .badgesArea {
        display: flex;
        align-self: flex-start;
        gap: 10px;

        @media (max-width: $breakpoint-md) {
          flex-direction: column;
        }

        .badge {
          @media (max-width: $breakpoint-md) {
            margin-top: 32px;
            margin-bottom: 16px;
            object-fit: cover;
          }
        }
      }
      .bottom {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
      }
      .strapline {
        color: $white;
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 300;
        strong {
          font-family: 'TWKLausanne-700';
        }
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 21px;
        }
        strong {
          font-family: 'TWKLausanne-700';
        }
      }
      .uppercta {
        display: flex;
        align-items: flex-start;
        button {
          display: flex;
          align-items: center;
          padding-bottom: 0;

          span.btnCopy {
            font-size: 0.972vw;
            padding-right: 0.694vw;
            @media (max-width: $breakpoint-md) {
              font-size: 14px;
              padding-right: 12px;
            }
          }
        }
      }
    }
  }
  .upper_footer:after {
    position: absolute;
    bottom: 0;
    content: ' ';
    width: 71.667vw;
    height: 0.139vw;
    background: $white_30;
    border-radius: 32px;
    @media (max-width: $breakpoint-md) {
      width: 90%;
    }
  }
  .middle_footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 2.361vw 14.167vw 2.361vw 14.167vw;
    @media (max-width: $breakpoint-md) {
      padding: 32px 16px 35.5px 16px;
      flex-flow: column;
      align-items: flex-start;
    }
    .footerMenuWrapper {
      max-width: 35vw;
    }
    .footerSocialMenuWrapper {
      display: flex;
      align-items: center;
      margin-right: 1.667vw;
    }

    .btnWrapper {
      @media (max-width: $breakpoint-md) {
        padding-top: 32px;
      }
      button {
        display: flex;
        align-items: center;
        font-size: 0.972vw;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
        }
      }
      .btn::after {
        background-image: url('/user-white.svg');
        content: ' ';
        width: 1.389vw;
        height: 1.389vw;
        background-size: cover;
        margin-left: 0.417vw;
        display: inline-block;
        @media (max-width: $breakpoint-md) {
          width: 20px;
          height: 20px;
          margin-left: 6px;
        }
      }
      .btn:hover::after {
        background-image: url('/user-red.svg');
      }
      .btn:active::after {
        background-image: url('/user-red-active.svg');
        background-size: contain;
        background-repeat: no-repeat;
        height: 1.2vw;
      }
    }
  }
  .middle_footer > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .lower_footer {
    display: flex;
    align-items: center;
    background: $lower_footer;
    width: 100%;
    padding: 0 14.167vw 0 14.167vw;
    height: 3.333vw;
    font-size: 0.972vw;
    line-height: 1.458vw;
    font-weight: 300;
    @media (max-width: $breakpoint-md) {
      flex-flow: column;
      align-items: flex-start;
      height: 82px;
      padding: 16px;
      font-size: 14px;
      line-height: 21px;
    }
    .footer_copyright {
      margin-right: 9.653vw;
      color: $medium_grey;
      @media (max-width: $breakpoint-md) {
        padding-bottom: 8px;
      }
    }
  }
}
